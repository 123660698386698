import Link from 'next/link';
import Image from 'next/image';

function Contactblock() {
  return (
    <div className='streamfield text-block contactblock'>
      <div className='content'>
        <Image
          className='icon'
          priority
          src='/contact.svg'
          alt='Telefon-Icon'
          height={48}
          width={48}
        />
        <h2>Haben Sie noch eine Frage?</h2>
        <p>Unser Team hilft Ihnen gerne weiter.</p>
        <Link href='/anfrage' className='button contactblock'>
          Zur Anfrage
        </Link>
      </div>
    </div>
  );
}

export default Contactblock;
