import type { Streamfield } from '@/types';
import Contactblock from '@components/Contactblock';
import Hero from '@components/Hero';
import Streamfields from '@streamfields/Streamfields';
import { BasePageProps, basePageWrap } from '../BasePage';

type Props = {
  title: string;
  body: string;
  content: Streamfield[];
  show_contact: boolean;
  showContactSticky: boolean;
} & BasePageProps;

function HomePage(props: Props) {
  const { title, body, content, show_contact, showContactSticky } = props;

  return (
    <div className='home-page'>
      <Hero title={title} subtitle={body} showContactSticky={showContactSticky} />
      <div className='flex-page'>{content ? <Streamfields fields={content} /> : null}</div>

      {show_contact ? <Contactblock /> : null}
    </div>
  );
}

export default basePageWrap<Props>(HomePage);
